<template>
  <div class="content">
    <common-breadcrumb />
    <card-item></card-item>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { subRoutes } from 'iebdc/router';
import zwwLogin from '@iebdc/utils/zwwLogin.js';
import { initData } from './common';
import { getNodesByParam } from '@iebdc/utils/common';
import { localDataArea } from '@iebdc/utils/local-data.js';
import { getCookie, addCookie, removeCookie } from '@iebdc/utils/cookies';
export default {
  name: 'index',
  data() {
    return {
      areaCode: '',
    };
  },
  mounted() {
    zwwLogin.analysisUrl(
      window.location.href,
      (areaCode) => {
        sessionStorage.setItem('flush', 1);
        this.getUrlData(areaCode);
      },
      () => {
        let url = window.location.href;
        url = url.replace(/(\?|#)[^'"]*/, '');
        window.history.pushState({}, 0, url);
        // this.$message.error(url);
        this.$router.push('/home/info-search/newQuanSZM-query');
      },
      (data) => {}
    );
  },
  methods: {
    getUrlData(areaCode) {
      console.log('areaCode', areaCode);
      if (window.CONFIG.APP_INFO.areaZw.hasOwnProperty(areaCode)) {
        this.areaCode = window.CONFIG.APP_INFO.areaZw[areaCode];
      }

      initData(() => {
        this.getCode(this.areaCode);
      });
    },
    getCode(areaCode) {
      let result = getNodesByParam(localDataArea.get(), 'code', areaCode, 'childrens');
      console.log(result);
      const nodes = [];
      const titles = [];
      let officeCode = '';
      let officeName = '';
      let jgmc = '';
      let djjg = '';

      if (result.length > 0) {
        result = result[0];

        if (result.pids != null) {
          result.pids = result.pids.substring(0, result.pids.length - 1);
          const data = result.pids.split(',');
          //三级自动选择
          let node = '';
          let title = '';
          data.forEach((ele) => {
            node = getNodesByParam(JSON.parse(localStorage.getItem('finalAreaDataLocal')), 'id', ele, 'childrens')[0];
            title = getNodesByParam(JSON.parse(localStorage.getItem('finalAreaDataLocal')), 'id', ele, 'childrens')[0];
            nodes.push(node.code);
            titles.push(title.title);
          });
          if (data.length === 1) {
            nodes.push(result.childrens[0].code);
            titles.push(result.childrens[0].title);
            officeCode = result.childrens[0].bsdtData[0].officeCode;
            officeName = result.childrens[0].bsdtData[0].officeName;
            jgmc = result.childrens[0].bsdtData[0].jgmc;
            djjg = result.childrens[0].bsdtData[0].djjg;
          } else if (data.length === 2) {
            officeCode = result.bsdtData[0].officeCode;
            officeName = result.bsdtData[0].officeName;
            jgmc = result.bsdtData[0].jgmc;
            djjg = result.bsdtData[0].djjg;
          }
          nodes.push(result.code);
          titles.push(result.title);
        } else {
          nodes.push('650000', '650100', '650101');
          titles.push('新疆维吾尔自治区', '乌鲁木齐市', '市辖区');
          officeCode = '650101-1';
          officeName = '乌市不动产登记中心-房产大厦';
          jgmc = '650100';
          djjg = '650101';
        }
      }
      console.log('nodes===', nodes);
      console.log('titles===', titles);
      this.$store.commit('dashboard/setSelectedShengCode', nodes[0]);
      this.$store.commit('dashboard/setSelectedShengTitle', titles[0]);
      this.$store.commit('dashboard/setSelectedShiCode', nodes[1]);
      this.$store.commit('dashboard/setSelectedShiTitle', titles[1]);
      this.$store.commit('dashboard/setSelectedAreaCode', nodes[2]);
      this.$store.commit('dashboard/setSelectedAreaTitle', titles[2]);
      this.$store.commit('dashboard/setSelectedBsdtCode', officeCode);
      this.$store.commit('dashboard/setSelectedBsdtTitle', officeName);
      this.$store.commit('dashboard/setSelectedOrganizationsCode', djjg);
      this.$store.commit('dashboard/setSelectedOrganizationsTitle', jgmc);
      addCookie('selectedBsdtTitle', this.$store.state.dashboard.selectedBsdtTitle, 1);
      addCookie('selectedBsdtCode', this.$store.state.dashboard.selectedBsdtCode, 1);
      addCookie('selectedOrganizationsTitle', this.$store.state.dashboard.selectedOrganizationsTitle, 1);
      addCookie('selectedOrganizationsCode', this.$store.state.dashboard.selectedOrganizationsCode, 1);
      addCookie('selectedShengTitle', this.$store.state.dashboard.selectedShengTitle, 1);
      addCookie('selectedShengCode', this.$store.state.dashboard.selectedShengCode, 1);
      addCookie('selectedShiTitle', this.$store.state.dashboard.selectedShiTitle, 1);
      addCookie('selectedShiCode', this.$store.state.dashboard.selectedShiCode, 1);
      addCookie('selectedAreaTitle', this.$store.state.dashboard.selectedAreaTitle, 1);
      addCookie('selectedAreaCode', this.$store.state.dashboard.selectedAreaCode, 1);
      this.$bus.$emit('selectedOrganizationsCode', djjg);
      this.$store.commit('dashboard/isShowBsdtDialog', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.main-list {
  margin-top: 5px;
  margin-bottom: 200px;
}
.content {
  min-height: 500px;
}
</style>
